











































































































































































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapActions,
  mapGetters
} from 'vuex'
import { cloneDeep } from '@/utils/functions'
import { Promocode } from '@/store/modules/promocodes/types'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import ValidatedBFormTextarea from '@/components/ui/ValidatedBFormTextarea.vue'

export default Vue.extend({
  name: 'PromocodeForm',

  components: {
    ValidatedBFormInput,
    ValidatedBFormTextarea
  },

  props: {
    promocode: {
      type: Object as PropType<Promocode>,
      required: true
    },
    profileId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      loading: false,
      localPromocode: {} as Promocode,
      types: [
        {
          value: 'subscription_promo_code',
          text: 'При подписке'
        },
        {
          value: 'personal_sending_promo_code',
          text: 'Ручная отправка'
        }
      ],
      discountTypes: [
        {
          value: 'fixed_discount',
          text: 'Фиксированный'
        },
        {
          value: 'percentage_discount',
          text: 'При подписке'
        }
      ]
    }
  },

  computed: {
    ...mapGetters('promocodes', [
      'getNewPromocode'
    ]),

    ...mapState('profiles', [
      'profile'
    ]),

    profileCurrency () {
      if (this.isSystemPromocodesRoute) return 'KGS'
      return this.profile?.profile_data?.currency === 417 ? 'KGS' : 'RUB'
    },

    readonly () {
      return !!this.localPromocode.id
    },

    promocodeType () {
      switch (this.localPromocode.type) {
        case 'subscription_promo_code':
          return 'При подписке'
        case 'personal_sending_promo_code':
          return 'Ручная отправка'
        default:
          return null
      }
    },

    isSystemPromocodesRoute () {
      return this.$route.name === 'promocodes'
    }
  },

  watch: {
    localPromocode: {
      deep: true,
      handler (val) {
        if (val.type === 'personal_sending_promo_code') {
          this.localPromocode.subscription_message = null
        }
      }
    }
  },

  created () {
    this.localPromocode = cloneDeep(this.promocode)

    if (!this.isSystemPromocodesRoute) {
      this.localPromocode.business_profile_id = this.profileId || this.profile?.profile_data?.id
    } else {
      this.localPromocode.type = 'personal_sending_promo_code'
    }
  },

  methods: {
    ...mapActions('promocodes', [
      'createPromocode',
      'updatePromocode'
    ]),

    cancelCreatePromocode () {
      this.$bvModal.hide('modal-edit-promocode')
      this.localPromocode = cloneDeep(this.getNewPromocode)
    },

    async onSubmit (isDraft: boolean) {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return
      await this.handleCreatePromocode()
    },

    async handleCreatePromocode () {
      this.loading = true
      const result = await this.createPromocode(this.localPromocode)
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail)
        this.loading = false
        return
      }

      this.$bvModal.hide('modal-edit-promocode')

      setTimeout(() => {
        this.$toast.success('Промокод создан')
      }, 100)

      this.$emit('promocode-created')
      this.loading = false
    },

    generateCode () {
      let result = ''
      const codeLength = 8
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length

      for (let i = 0; i < codeLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      this.localPromocode.code = result.toUpperCase()
    }
  }
})
