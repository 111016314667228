
















































import Vue from 'vue'
import Loading from '@/components/Loading/index.vue'
import Pagination from '@/components/Pagination/index.vue'
import { PAGINATION_LIMIT } from '@/common/constants'

export default Vue.extend({
  name: 'PromocodesList',

  components: {
    Loading,
    Pagination
  },

  props: {
    fields: {
      type: Array,
      default: () => []
    },

    promocodes: {
      type: Array,
      default: () => []
    },

    loading: {
      type: Boolean,
      default: () => true
    },

    offset: {
      type: Number,
      default: () => 0
    },

    limit: {
      type: Number,
      default: () => PAGINATION_LIMIT
    },

    showCreateLinkInEmptyMessage: {
      type: Boolean,
      default: () => true
    },

    emptyMessage: {
      type: String,
      default: () => 'Промокоды не найдены'
    }
  },

  methods: {
    promocodeType (typeSlug: string) {
      switch (typeSlug) {
        case 'subscription_promo_code':
          return 'При подписке'
        case 'personal_sending_promo_code':
          return 'Ручная отправка'
        default:
          return null
      }
    },

    goPrevPage () {
      if (this.offset <= 0) {
        this.$emit('update:offset', 0)
        return
      }
      this.$emit('update:offset', this.offset - this.limit)
    },

    goNextPage () {
      this.$emit('update:offset', this.offset + this.limit)
    }
  }
})
